import React, { useState, useEffect } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import CreatePasswordUI from "./CreatePassword";
import useStyles from "./CreatePassword.styles";
import { createPwd, getUserDataDetail } from "../../redux/actions/origination.action";

export function CreatePassword() {
  const history = useNavigate();
  const { phoneNumber, user_id } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();

  const { pwdConfirmed, userDataDetail} = useSelector((state) => state.originationReducer);

  const [password, setPassword] = useState("");
  const [isValidatePassword, setIsValidatePassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidateConfirmPassword, setIsValidateConfirmPassword] =
    useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showValidatePassword, setShowValidatePassword] = useState(false);

  const validateNipNumber = (nipNumberHandle) => {
    const re = /^[0-9]{6}$/im;
    return re.test(nipNumberHandle);
  };

  const handleChangePassword = (value) => {
    const passwordNumberHandle = value;
    if (passwordNumberHandle !== "") {
      if (!validateNipNumber(passwordNumberHandle)) {
        setErrorPassword("Contraseña invalida");
        setIsValidatePassword(false);
      } else {
        setErrorPassword("");
        setIsValidatePassword(true);
      }
    }
    setPassword(value);
  };

  const handleChangeConfirmPassword = (value) => {
    const passwordNumberHandle = value;
    if (passwordNumberHandle !== "") {
      if (!validateNipNumber(passwordNumberHandle)) {
        setErrorConfirmPassword("Contraseña invalida");
        setIsValidateConfirmPassword(false);
      } else if (passwordNumberHandle !== password) {
        setErrorConfirmPassword("La contraseña no coincide");
        setIsValidateConfirmPassword(false);
      } else {
        setErrorConfirmPassword("");
        setIsValidateConfirmPassword(true);
      }
    }
    setConfirmPassword(value);
  };

  const onContinueAction = () => {
    dispatch(createPwd("SK", user_id, password));
  };

  useEffect(() => {
    if (pwdConfirmed) {
      if(userDataDetail?.data?.request?.welcome_screen_number === 0
      || process.env.REACT_APP_MAP_COUNTRY === 'do'){
        history(`/${phoneNumber}/${user_id}/advisor-contact`, { replace: true });
      } else {
        history(`/${phoneNumber}/${user_id}/advisor-welcome`, { replace: true });
      }
    }
  }, [pwdConfirmed]);

  useEffect(() => {
    dispatch(getUserDataDetail(user_id, 'SK'));
  }, []);



  return (
    <CreatePasswordUI
      classes={classes}
      _handleChangePassword={handleChangePassword}
      _handleChangeConfirmPassword={handleChangeConfirmPassword}
      isValidatePassword={isValidatePassword}
      isValidateConfirmPassword={isValidateConfirmPassword}
      errorPassword={errorPassword}
      errorConfirmPassword={errorConfirmPassword}
      _onContinueAction={onContinueAction}
      setShowPassword={setShowPassword}
      showPassword={showPassword}
      setShowValidatePassword={setShowValidatePassword}
      showValidatePassword={showValidatePassword}
      password={password}
      confirmPassword={confirmPassword}
    />
  );
}

CreatePassword.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default CreatePassword;
