function I18n(country) {
  const langMap = {
    mx: {
      rfc: 'RFC',
      rfc_length: 13,
      rfc_link: 'https://www.mi-rfc.com.mx/consulta-rfc-homoclave',
      format_rfc: false,
      generate_rfc: true,
      grocery: 'Tienda de Abarrotes y Misceláneas',
      grocery_value: 'Tienda de Abarrotes y Misceláneas',
      neighborhood: 'Colonia',
      include_zip_code: true,
      company_name: 'FRESH LENDING SAPI DE CV',
    },
    do: {
      rfc: 'RNC',
      rfc_length: 13,
      rfc_link: 'https://www.dgii.gov.do/app/WebApps/ConsultasWeb/consultas/rnc.aspx#',
      format_rfc: true,
      generate_rfc: false,
      grocery: 'Colmado',
      grocery_value: 'Tienda de Abarrotes y Misceláneas',
      neighborhood: 'Sector',
      include_zip_code: false,
      company_name: 'FRESH LENDING S.A.C.',
    },
  };
  return langMap[country];
}

export default I18n;
