/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { capitalize, InputAdornment } from '@material-ui/core';
import { Close, Done } from '@material-ui/icons';

import { BoxLayout, Button, MenuItem, Text, TextInput } from '../../components';
import Stepper from '../../components/Stepper';

export function TellMoreAbout({
  classes,
  handleChangeInput,
  handleSubmit,
  values,
  errorMessages,
  businessTypeData = [],
  salesRangeData = [],
  _i18n,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
      <Stepper activeBars={3} activeStep={1}/>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          Negocio
        </Text>
        <BoxLayout className={classes.formContainer}>
          <TextInput
            className={classes.formElement}
            label="Nombre del negocio"
            variant="standard"
            value={capitalize(values.shop_name)}
            onChange={handleChangeInput("shop_name")}
            helperText={errorMessages.shop_name}
            error={errorMessages.shop_name !== ""}
            focused={
              values.shop_name.length >= 3 && errorMessages.shop_name === ""
                ? true
                : false
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {values.shop_name.length >= 3 &&
                    errorMessages.shop_name === "" && (
                      <Done style={{ color: "#009805" }} />
                    )}
                  {values.shop_name.length >= 3 &&
                    errorMessages.shop_name !== "" && (
                      <Close style={{ color: "#ff664d" }} />
                    )}
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            className={classes.formElement}
            label="Antigüedad con el negocio"
            variant="standard"
            select
            value={values.shop_age}
            onChange={handleChangeInput("shop_age")}
            helperText={errorMessages.shop_age}
            error={errorMessages.shop_age !== ""}
            focused={values.shop_age !== "" ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.selectAdornment}>
                  {values.shop_age !== "" && (
                    <Done
                      style={{
                        color: "#009805",
                        position: 'relative',
                        right: 20,
                        zIndex: 1,
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="1">1 año</MenuItem>
            <MenuItem value="2">2 años</MenuItem>
            <MenuItem value="3">3 años</MenuItem>
            <MenuItem value="4">4 años o más</MenuItem>
          </TextInput>
          <TextInput
            className={classes.formElement}
            label="¿Cuál es el giro de tu tienda?"
            variant="standard"
            select
            value={values.business_type}
            onChange={handleChangeInput("business_type")}
            helperText={errorMessages.business_type}
            error={errorMessages.business_type !== ""}
            focused={values.business_type !== "" ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.selectAdornment}>
                  {values.business_type !== "" && (
                    <Done
                      style={{
                        color: "#009805",
                        position: 'relative',
                        right: 20,
                        zIndex: 1,
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          >
            {businessTypeData.length !== 0 ? (
              businessTypeData.filter((item) => item !== 'Otros')
                .sort()
                .map((businessType) => (
                <MenuItem value={businessType === _i18n.grocery
                  ? _i18n.grocery_value
                  : businessType}>{businessType}</MenuItem>
                ))
            ) : (
              <MenuItem value="">Sin datos</MenuItem>
            )}
            {businessTypeData.length !== 0 && (
              <MenuItem value="Otros">Otros</MenuItem>
            )}
          </TextInput>
          {values.business_type === "Otros" && (
            <TextInput
              className={classes.formElement}
              label="¿Qué giro es tu negocio?"
              variant="standard"
              value={capitalize(values.other_businessType)}
              onChange={handleChangeInput("other_businessType")}
              error={errorMessages.other_businessType !== ""}
              focused={
                values.other_businessType !== "" &&
                errorMessages.other_businessType === ""
                  ? true
                  : false
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.selectAdornment}>
                    {values.other_businessType !== "" &&
                      errorMessages.other_businessType === "" && (
                        <Done style={{ color: "#009805" }} />
                      )}
                    {values.other_businessType !== "" &&
                      errorMessages.other_businessType !== "" && (
                        <Close style={{ color: "#ff664d" }} />
                      )}
                  </InputAdornment>
                ),
              }}
            />
          )}
          <TextInput
            className={classes.formElement}
            label="Venta promedio semanal"
            variant="standard"
            select
            value={values.average_sale}
            onChange={handleChangeInput("average_sale")}
            helperText={errorMessages.average_sale}
            error={errorMessages.average_sale !== ""}
            focused={values.average_sale !== "" ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.selectAdornment}>
                  {values.average_sale !== "" && (
                    <Done
                      style={{
                        color: "#009805",
                        position: 'relative',
                        right: 20,
                        zIndex: 1,
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          >
            {salesRangeData.length !== 0 ? (
              salesRangeData.map((salesRange) => (
                <MenuItem value={salesRange.id}>
                  {salesRange.range_description}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">Sin datos</MenuItem>
            )}
          </TextInput>
          <Button
            newCustomStyle={styles.newStyleButton}
            variant="contained"
            color="primary"
            isDisabled={
              values.business_type !== "Otros"
                ? !(
                    values.shop_name !== "" &&
                    values.shop_age !== "" &&
                    values.business_type !== "" &&
                    values.average_sale !== ""
                  )
                : !(
                    values.shop_name !== "" &&
                    values.shop_age !== "" &&
                    values.business_type === "Otros" &&
                    values.other_businessType !== "" &&
                    values.average_sale !== ""
                  )
            }
            onClick={() => handleSubmit()}
          >
            Continuar
          </Button>
        </BoxLayout>
      </BoxLayout>
    </BoxLayout>
  );
}

export default TellMoreAbout;

const styles = {
  newStyleButton: {
    marginTop: 130,
    marginBottom: 20,
  },
};

TellMoreAbout.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeInput: PropTypes.func,
  handleChangeCheckbox: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  errorMessages: PropTypes.object,
  zipCodeData: PropTypes.array,
  _i18n: PropTypes.object,
};

TellMoreAbout.defaultProps = {
  handleChangeInput: () => {},
  handleChangeCheckbox: () => {},
  handleSubmit: () => {},
  values: {},
  errorMessages: {},
  zipCodeData: [],
  _i18n: {},
};
