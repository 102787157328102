import React from 'react';

import DownloadUI from './Download';
import useStyles from './Download.styles';

export function Download(props) {
  const classes = useStyles();

  const _onContinueAction = () => {
    window.location.href = process.env.REACT_APP_PLAYSTORE_LINK;
  };

  return <DownloadUI classes={classes} _onContinueAction={_onContinueAction} />;
}

export default Download;
