import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router';

import AdditionalDataUI from './AdditionalData';
import useStyles from './AdditionalData.styles';
import {
  clearAdditionalData,
  getUserDataDetail,
  sendAdditionalData,
} from '../../redux/actions/origination.action';
import generatedRFC from '../../constants/generatedRFC';
import I18n from '../../i18n';

export const AdditionalData = () => {
  const { user_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const saved = sessionStorage.getItem("values");
  console.log("Guardados: ", saved);
  const initialValue = JSON.parse(saved);
  const i18n = I18n(process.env.REACT_APP_MAP_COUNTRY);

  const errorEmptyMsgs = {
    rfc: `* Ingresa el ${i18n.rfc} !`,
  };

  const [values, setValues] = useState({
    rfc: "",
    is_my_rfc: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    rfc: "",
  });

  const { putAdditionalData } = useSelector(
    (state) => state.originationReducer
  );

  useEffect(() => {
    if (Object.keys(putAdditionalData).length > 0) {
      dispatch(clearAdditionalData());
      dispatch(getUserDataDetail(user_id, "SK"));
    }
  }, [putAdditionalData]);

  useEffect(() => {
    if (
      initialValue !== undefined &&
      initialValue !== null &&
      initialValue?.names !== "" &&
      initialValue?.first_lastname !== "" &&
      initialValue?.day !== "" &&
      initialValue?.month !== "" &&
      initialValue?.year !== "" &&
      i18n.generate_rfc
    ) {
      const dateFormat = moment(
        new Date(initialValue?.year, initialValue?.month - 1, initialValue?.day)
      ).format("YYMMDD");
      const rfcGenerated = generatedRFC(
        initialValue.first_lastname,
        initialValue.second_lastname,
        initialValue.names,
        dateFormat
      );
      setValues((prevState) => ({
        ...prevState,
        rfc: rfcGenerated,
      }));
    }
  }, []);

  const handleChangeInput = (prop) => (event) => {
    let { value } = event.target;
    if (prop === "rfc") {
      value = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      value = formatTaxId(value);
    }
    setValues({ ...values, [prop]: value });
  };

  const handleChangeCheckbox = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const handleSubmit = () => {
    let hasError = false;
    const errors = {};

    // Empty validator error
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v === 0) {
        hasError = true;
        errors[k] = errorEmptyMsgs[k];
      } else {
        errors[k] = "";
      }
    });

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    _sendAdditionalData();
  };

  const _sendAdditionalData = () => {
    // Send data to backend
    let body = {
      tax_payer_id: values.rfc,
    };
    dispatch(sendAdditionalData("SK", user_id, body));
  };

  const redirectAction = () => {
    window.open(i18n.rfc_link, '_blank');
  };

  const formatTaxId = (value) => {
    if (i18n.format_rfc) {
      const numericValue = value.replace(/\D/g, '');
      if (numericValue.length <= 3) {
        return numericValue; // XXX
      } else if (numericValue.length <= 10) {
        return numericValue.replace(/^(\d{3})(\d{1,7})$/, '$1-$2'); // XXX-XXXXXXX
      } else if (numericValue.length <= 11) {
        return numericValue.replace(/^(\d{3})(\d{7})(\d{1})$/, '$1-$2-$3'); // XXX-XXXXXXX-X
      }
      return numericValue.substring(0, 11);
    }
    return value;
  };

  return (
    <AdditionalDataUI
      classes={classes}
      handleChangeCheckbox={handleChangeCheckbox}
      handleChangeInput={handleChangeInput}
      handleSubmit={handleSubmit}
      values={values}
      errorMessages={errorMessages}
      _redirectAction={redirectAction}
      _i18n={i18n}
    />
  );
};

export default AdditionalData;
