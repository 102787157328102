import React from 'react';
import PropTypes from 'prop-types';

import { BoxLayout, Button, CheckBox, Text, TextInput } from '../../components';
import Stepper from '../../components/Stepper';

export function AdditionalData({
  classes,
  handleChangeCheckbox,
  handleChangeInput,
  handleSubmit,
  values,
  errorMessages,
  _redirectAction,
  _i18n,
}) {
  return (
    <BoxLayout className={classes.page}>
    <Stepper activeBars={2} activeStep={1}/>
      <BoxLayout className={classes.container}>
        <BoxLayout className={classes.formContainer}>
          <Text
            className={classes.mainTitle}
            variant="h2"
            color="initial"
            align="left"
          >
            {`Asegúrate que tu ${_i18n.rfc} esté correcto`}
          </Text>
          <TextInput
            className={classes.formElement}
            label={`${_i18n.rfc} (${_i18n.rfc_length} dígitos)`}
            variant="standard"
            value={values.rfc}
            onChange={handleChangeInput('rfc')}
            helperText={errorMessages.rfc}
            error={errorMessages.rfc !== ''}
            inputProps={{ maxLength: _i18n.rfc_length }}
          />
          <a
            onClick={() => _redirectAction()}
            className={classes.hiperTextPrivacy}
          >
            {`¿Desconoces tu ${_i18n.rfc}? Consúltalo aquí`}
          </a>
          <div className={classes.doubleParamForm}>
            <CheckBox
              onChange={handleChangeCheckbox}
              checked={values.is_my_rfc}
              name="is_my_rfc"
              disabled={
                !(values.rfc.length === _i18n.rfc_length)
              }
              />
            <Text
              variant="h4"
              align="left"
            >
              {`Mi ${_i18n.rfc} es correcto`}
            </Text>
          </div>
        </BoxLayout>
        <Button
          variant="contained"
          color="primary"
          isDisabled={
            !(
              values.rfc.length === _i18n.rfc_length &&
              values.is_my_rfc === true
            )
          }
          onClick={() => handleSubmit()}
        >
          Continuar
        </Button>
      </BoxLayout>
    </BoxLayout>
  );
}

export default AdditionalData;

const styles= {
  consultRFC: {
    backgroundColor: "#FFF",
    color: "#3050ff",
  }
};

AdditionalData.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeInput: PropTypes.func,
  handleTrim: PropTypes.func,
  handleDiacritialMarks: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  errorMessages: PropTypes.object,
  _i18n: PropTypes.object,
};
