import React from 'react';
import PropTypes from 'prop-types';

import { InputAdornment } from '@material-ui/core';
import { Close, Done } from '@material-ui/icons';

import { BoxLayout, Button, MenuItem, Text, TextInput } from '../../components';
import Stepper from '../../components/Stepper';

export function WheresLivingForm({
  classes,
  handleChangeInput,
  handleSubmit,
  values,
  errorMessages,
  zipCodeData,
  _i18n,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
      <Stepper activeBars={4} activeStep={1}/>
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          Domicilio de tu Negocio
        </Text>
        <BoxLayout className={classes.formContainer}>
          {_i18n.include_zip_code && <TextInput
            className={classes.formElement}
            label="Código Postal"
            variant="standard"
            value={values.zip_code}
            type="number"
            inputProps={{
              maxLength: 5,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            onChange={handleChangeInput('zip_code')}
            helperText={errorMessages.zip_code}
            error={errorMessages.zip_code !== ''}
            focused={values.zip_code.length === 5 && errorMessages.zip_code ===
            '' ? true : false}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {values.zip_code.length === 5 && errorMessages.zip_code ===
                  '' &&
                  <Done
                    style={{ color: '#009805' }}
                  />
                }
                {values.zip_code.length === 5 && errorMessages.zip_code !==
                  '' &&
                  <Close
                    style={{ color: '#ff664d' }}
                  />
                }
              </InputAdornment>,
            }}
          />}
          <TextInput
            className={classes.formElement}
            label="Estado"
            variant="standard"
            value={values.state}
            onChange={handleChangeInput("state")}
            helperText={errorMessages.state}
            error={errorMessages.state !== ""}
            disabled={_i18n.include_zip_code && 'disabled'}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {values.state.length >= 2 && errorMessages.state === '' &&
                  <Done
                    style={{ color: '#009805' }}
                  />
                }
                {values.state.length >= 2 && errorMessages.state !== '' &&
                  <Close
                    style={{ color: '#ff664d' }}
                  />
                }
              </InputAdornment>,
            }}
          />
          <TextInput
            className={classes.formElement}
            label="Municipio"
            variant="standard"
            value={values.municipality}
            onChange={handleChangeInput("municipality")}
            helperText={errorMessages.municipality}
            error={errorMessages.municipality !== ""}
            disabled={_i18n.include_zip_code && 'disabled'}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {values.municipality.length >= 2 &&
                  errorMessages.municipality === '' &&
                  <Done
                    style={{ color: '#009805' }}
                  />
                }
                {values.municipality.length >= 2 &&
                  errorMessages.municipality !== '' &&
                  <Close
                    style={{ color: '#ff664d' }}
                  />
                }
              </InputAdornment>,
            }}
          />
          <TextInput
            className={classes.formElement}
            label={_i18n.neighborhood}
            variant="standard"
            select={_i18n.include_zip_code}
            value={values.suburb}
            onChange={handleChangeInput("suburb")}
            helperText={errorMessages.suburb}
            error={errorMessages.suburb !== ""}
            focused={values.suburb !== "" ? true : false}
            InputProps={{
              endAdornment: <InputAdornment position="end" className={classes.selectAdornment}>
                {values.suburb !== "" &&
                  <Done
                    style={{
                      color: "#009805",
                      position: 'relative',
                      right: 20,
                      zIndex: 1,
                    }}
                  />
                }
              </InputAdornment>
            }}
          >
            {zipCodeData.length !== 0 ? (
              zipCodeData.map((zipCode) => (
                <MenuItem value={zipCode.neighborhood}>
                  {zipCode.neighborhood}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">Sin datos</MenuItem>
            )}
          </TextInput>
          <TextInput
            className={classes.formElement}
            label="Calle"
            variant="standard"
            value={values.street}
            onChange={handleChangeInput("street")}
            helperText={errorMessages.street}
            error={errorMessages.street !== ""}
            focused={values.street.length >= 3 && errorMessages.street === "" ? true : false}
            inputProps={{
              maxLength: 50,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {values.street.length >= 3 && errorMessages.street === "" &&
                  <Done
                    style={{ color: "#009805" }}
                  />
                }
                {values.street.length >= 3 && errorMessages.street !== "" &&
                  <Close
                    style={{ color: "#ff664d" }}
                  />
                }
              </InputAdornment>
            }}
          />
          <div className={classes.doubleParamForm}>
            <TextInput
              className={classes.doubleElementForm}
              label="Número exterior"
              variant="standard"
              value={values.numberStreet}
              onChange={handleChangeInput("numberStreet")}
              helperText={errorMessages.numberStreet}
              error={errorMessages.numberStreet !== ""}
              focused={values.numberStreet !== "" && errorMessages.numberStreet === "" ? true : false}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  {values.numberStreet !== "" && errorMessages.numberStreet === "" &&
                    <Done
                      style={{ color: "#009805" }}
                    />
                  }
                  {values.numberStreet !== "" && errorMessages.numberStreet !== "" &&
                    <Close
                      style={{ color: "#ff664d" }}
                    />
                  }
                </InputAdornment>
              }}
            />
            <TextInput
              className={classes.doubleElementForm}
              label="Número interior"
              variant="standard"
              value={values.apartmentNumber}
              onChange={handleChangeInput("apartmentNumber")}
              helperText={errorMessages.apartmentNumber}
              error={errorMessages.apartmentNumber !== ""}
              focused={values.apartmentNumber !== "" && errorMessages.apartmentNumber === "" ? true : false}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  {values.apartmentNumber !== "" && errorMessages.apartmentNumber === "" &&
                    <Done
                      style={{ color: "#009805" }}
                    />
                  }
                  {values.apartmentNumber !== "" && errorMessages.apartmentNumber !== "" &&
                    <Close
                      style={{ color: "#ff664d" }}
                    />
                  }
                </InputAdornment>
              }}
            />
          </div>
          <Text
            className={classes.authorization}
            variant="h6"
            color="initial"
            align="center"
          >
            {`Al continuar autorizas la consulta de historial crediticio a favor ${_i18n.company_name}`}
          </Text>
          <Button
            variant="contained"
            color="primary"
            isDisabled={
              !(
                values.zip_code.length === 5 &&
                values.state !== "" &&
                values.municipality !== "" &&
                values.suburb !== "" &&
                values.street !== "" &&
                values.numberStreet !== ""
              )
            }
            onClick={() => handleSubmit()}
          >
            Continuar
          </Button>
        </BoxLayout>
      </BoxLayout>
    </BoxLayout>
  );
}

export default WheresLivingForm;

WheresLivingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeInput: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  errorMessages: PropTypes.object,
  zipCodeData: PropTypes.array,
  _i18n: PropTypes.object,
};
