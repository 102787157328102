import React from 'react';
import PropTypes from 'prop-types';

import { BoxLayout, Button, Text } from '../../components';
import Images from '../../assets/images';

export function Download({ classes, _onContinueAction }) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
        <img
          style={{ width: "320px" }}
          src={process.env.REACT_APP_MAP_COUNTRY === 'mx'
            ? Images.download
            : Images.downloadRD}
          alt="img-background"
        />
        <Text
          className={classes.mainTitle}
          variant="h2"
          color="initial"
          align="justify"
        >
          ¡Descarga la app!
        </Text>
        <Text
          className={classes.subTitle}
          variant="h4"
          color="initial"
          align="left"
        >
          En ella finalizarás la creación de tu cuenta y
          podrás gestionar tu línea de crédito.
        </Text>
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          onClick={() => _onContinueAction()}
        >
          Descargar App
        </Button>
      </BoxLayout>
    </BoxLayout>
  );
}

export default Download;

Download.propTypes = {
  classes: PropTypes.object.isRequired,
  _onContinueAction: PropTypes.func,
};
