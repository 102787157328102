import React from 'react';
import PropTypes from 'prop-types';

import { BoxLayout, Text } from '../../components';
import Images from '../../assets/images';
import Stepper from '../../components/Stepper';

export function AdvisorContact({
  classes,
  callAdvisor,
  withPartner,
  onClickWhatsapp,
}) {
  return (
    <BoxLayout className={classes.page}>
      <BoxLayout className={classes.container}>
      <Stepper activeStep={2} isEvaluation/>
        <div className={classes.imagesContainer}>
          <img
            className={classes.backgroundImage}
            src={process.env.REACT_APP_MAP_COUNTRY === 'mx'
              ? Images.approvedLoan
              : Images.approvedLoanRD}
            alt="approved-loan"
          />
        </div>
        <Text
          className={classes.mainTitle}
          variant="h1"
          color="initial"
          align="left"
        >
          Tu préstamo fue pre aprobado
        </Text>
        <Text
          className={classes.subTitle}
          variant="h4"
          color="initial"
          align="justify"
        >
          Para continuar con tu proceso de registro necesitas contactar a un asesor.
        </Text>
        <button onClick={() => callAdvisor()} className={classes.itemContainer}>
          <img className={classes.image} src={Images.llamarasesor} />
          <Text variant="h7" color="initial" align="justify">
            Continuar por llamada
          </Text>
        </button>
        <button onClick={() => withPartner()} className={classes.itemContainer}>
          <img className={classes.image} src={Images.asesor} />
          <Text variant="h7" color="initial" align="justify">
            Estoy con un asesor
          </Text>
        </button>
        <button
          onClick={() => onClickWhatsapp()}
          className={classes.itemContainerWhatsApp}
        >
          <img className={classes.image} src={Images.whatsappWhite} />
          <Text variant="h7" color="initial" align="justify" className={classes.textWhats}>
            Chatear con un asesor
          </Text>
        </button>
      </BoxLayout>
    </BoxLayout>
  );
}

export default AdvisorContact;

AdvisorContact.propTypes = {
  classes: PropTypes.object.isRequired,
  callAdvisor: PropTypes.func,
};
